<template>
  <div class="inline-block w-full">
    <template
      v-for="(item, index) in sidebarRoute"
      :key="'p' + index">
      <a-sub-menu
        v-if="!item.hidden && item.children"
        :key="concatLink(item)">
        <template #icon>
          <svg-icon
            v-if="item.meta && !props.hideIcon"
            :name="item.meta.icon"></svg-icon>
        </template>
        <template #title>
          <span v-if="item.meta">{{ item.meta.title }}</span>
        </template>
        <template v-if="item.children">
          <SlideItem
            v-if="item.path"
            :hideIcon="props.hideIcon"
            :sidebarRoute="item.children"
            :parentPath="[...props.parentPath, item.path]"></SlideItem>
          <template
            v-for="(it, index) in item.children"
            :key="'c' + index"></template>
        </template>
      </a-sub-menu>
      <a-menu-item
        :key="concatLink(item)"
        v-if="!item.hidden && !item.children">
        <template #icon>
          <svg-icon
            v-if="item.meta && !props.hideIcon"
            :name="item.meta.icon"></svg-icon>
        </template>
        <span v-if="item.meta">{{ item.meta.title }}</span>
      </a-menu-item>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { routerData } from '@/interface/router'

const props = defineProps({
  parentPath: {
    type: Array<string>,
    default: []
  },
  hideIcon: {
    type: Boolean,
    default: false
  },
  sidebarRoute: {
    type: Array<routerData>
  }
})

const concatLink = (obj: routerData) => {
  if (obj.path && !props.parentPath.length && obj.path.startsWith('/')) {
    return obj.path
  } else if (obj.path && props.parentPath.length && !obj.path.startsWith('/')) {
    let str = [...props.parentPath, obj.path].join('/')
    if (str.startsWith('/')) {
      return str
    } else {
      return '/' + str
    }
  } else {
    return '/' + obj.path
  }
}
</script>
<style lang="less">
.arco-menu-light .arco-menu-item.arco-menu-selected {
  background-color: #e8f4ff;
}
</style>
