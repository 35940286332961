<template>
  <a-result
    :title="word"
    :status="props.type">
    <template #subtitle>
      <div
        v-if="props.type === 'success'"
        class="mt-sm">
        <div>
          {{ props.content && props.content + '，' }}({{ current }}S后自动{{
            props.content ? (props.pathName ? '跳转' + props.pathName : '跳转列表') : '关闭'
          }})
        </div>
      </div>
      <div
        v-else
        class="mt-sm">
        <div>{{ props.message ? props.message : '操作失败!' }}</div>
      </div>
    </template>
    <template #extra>
      <a-space size="medium">
        <a-button
          :type="btn"
          @click="onCancel">
          {{ props.type === 'success' ? (props.cancelText ? props.cancelText : '继续添加') : '关闭' }}
        </a-button>
        <a-button
          type="primary"
          @click="onOk"
          v-if="props.type === 'success'">
          {{ props.okText }}
        </a-button>
      </a-space>
    </template>
  </a-result>
</template>

<script lang="ts" setup>
import { ref, computed, nextTick } from 'vue'
/**
 * 初始化页面函数
 *
 * **/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props: any = defineProps({
  // 'onOk', 'content', 'type', 'onCancel', 'okText', 'cancelText'
  onOk: {
    type: Function
  },
  onCancel: {
    type: Function
  },
  okText: {
    type: String,
    default: '返回列表'
  },
  cancelText: {
    type: String,
    default: '关闭'
  },
  content: {
    type: String,
    default: undefined
  },
  pathName: {
    type: String,
    default: undefined
  },
  message: {
    type: String,
    default: undefined
  },
  type: {
    type: String,
    default: 'success'
  }
})

const list = [
  {
    label: '操作成功',
    value: 'success'
  },
  {
    label: '操作失败',
    value: 'error'
  },
  {
    label: '警告',
    value: 'warning'
  },
  {
    label: '提示',
    value: 'info'
  },
  {
    label: '成功',
    value: 'success'
  },
  {
    label: '403',
    value: '403'
  },
  {
    label: '404',
    value: '404'
  },
  {
    label: '500',
    value: '500'
  }
]

const word = computed(() => {
  const arr = list.filter((el: { value: string }) => {
    return el.value == props.type
  })
  if (arr && arr.length) {
    return arr[0].label
  } else {
    return ''
  }
})
const btn = computed<'primary' | 'text' | 'dashed' | 'outline' | 'secondary' | undefined>(() => {
  if (props.type == 'success') {
    return undefined
  } else {
    return 'primary'
  }
})

const emits = defineEmits(['close'])
let current = ref(3)

let timer = ref()
nextTick(() => {
  if (props.type == 'success') {
    timer.value = setInterval(() => {
      current.value--
      if (current.value == 0) {
        clearInterval(timer.value)
        emits('close')
        if (props.onOk) props.onOk()
      }
    }, 1000)
  }
})
const onCancel = () => {
  if (props.onCancel) props.onCancel()
  if (props.type == 'success') {
    clearInterval(timer.value)
  }
  emits('close')
}
const onOk = () => {
  if (props.onOk) props.onOk()
  if (timer.value) clearInterval(timer.value)
  emits('close')
}
</script>
