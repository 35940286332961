<template>
  <div class="w-full min-w-screen min-w-[768px] h-screen flex flex-row">
    <Slide v-if="setting.isShowSidebar"></Slide>

    <div class="relative bg-bg-normal flex-1 transition-width duration-500 ease-linear h-full">
      <div
        class="relative flex flex-col h-screen overflow-auto"
        :style="{
          width: isHideSlide
            ? '100vw'
            : isFold
            ? 'calc(100vw - ' + slideStore().slideFoldWidth + 'px)'
            : 'calc(100vw - ' + slideStore().slideFullWidth + 'px)',
          minWidth: '768px'
        }">
        <div class="w-full h-full">
          <Menu
            class="sticky top-0 left-0 z-10 bg-white"
            @openMsg="openMsg"
            ref="menuRef"></Menu>
          <div :style="{ height: 'calc(100% - ' + config.header + 'px)' }">
            <router-view v-slot="{ Component, route }">
              <transition
                name="fade-transform"
                mode="out-in">
                <keep-alive :include="cachedViews">
                  <component
                    :is="Component"
                    :key="route.path" />
                </keep-alive>
              </transition>
            </router-view>
          </div>
        </div>
      </div>
      <div>
        <!-- <UserMsg
          v-if="visibleMsg"
          v-model:visible="visibleMsg"></UserMsg> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Slide from './component/slide.vue'
import Menu from './component/top-menu.vue'
// import UserMsg from './component/userMsg.vue'
import { ref, computed, onUnmounted, onMounted } from 'vue'
import { tagsViewStore } from '@/store/tagsView'
import { settingStore } from '@/store/setting'
import { slideStore } from '@/store/slide'
import config from '@/static/auth.json'

const setting = settingStore()
const tagsView = tagsViewStore()
const menuRef = ref()
const visibleMsg = ref(false)
const cachedViews = computed(() => {
  return tagsView.cachedViews
})

let isFold = computed(() => {
  return slideStore().isFoldSlide
})
let isHideSlide = computed(() => {
  return slideStore().isHideSlide
})

const openMsg = () => {
  visibleMsg.value = true
}

onMounted(() => {
  window.addEventListener('load', initPage)
  window.addEventListener('resize', initPage)
})

onUnmounted(() => {
  window.removeEventListener('load', initPage)
  window.removeEventListener('resize', initPage)
})

const initPage = () => {
  const timer = setTimeout(() => {
    clearTimeout(timer)
    if (document.body.offsetWidth <= 1200) {
      if (document.body.offsetWidth <= 768) {
        slideStore().setIsHideSlide(true)
        return
      }
      slideStore().setIsFoldSlide(true)
      slideStore().setIsHideSlide(false)
    } else {
      slideStore().setIsFoldSlide(false)
      slideStore().setIsHideDrawer(false)
    }
  }, 400)
}

// initPage()
</script>
<style lang="less" scoped>
.min-w-screen {
  .arco-scrollbar {
    height: 100%;
  }
}
</style>
